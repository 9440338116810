const size = {
  mobileS: '560px',
  mobileM: '664px',
  mobileL: '840px',
  laptopS: '1024px',
  laptopM: '1340px',
};

export const device = {
  mobileS: `(max-width: ${size.mobileS})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  laptopS: `(max-width: ${size.laptopS})`,
  laptopM: `(max-width: ${size.laptopM})`,
  // laptop: `(max-width: ${size.laptop})`,
  // laptopL: `(max-width: ${size.laptopL})`,
  // desktop: `(max-width: ${size.desktop})`,
  // desktopL: `(max-width: ${size.desktop})`,
};

const whiteShine = `
   background: radial-gradient(
    130% 130% at 50% 50%,
    #ffffff 0%,
    rgba(255, 255, 255, 0.997018) 8.07%,
    rgba(253, 253, 253, 0.988237) 15.54%,
    rgba(251, 251, 251, 0.973907) 22.5%,
    rgba(248, 248, 248, 0.954278) 29.04%,
    rgba(245, 245, 245, 0.9296) 35.26%,
    rgba(240, 240, 240, 0.900122) 41.25%,
    rgba(236, 236, 236, 0.866093) 47.1%,
    rgba(230, 230, 230, 0.827763) 52.9%,
    rgba(224, 224, 224, 0.785382) 58.75%,
    rgba(217, 217, 217, 0.7392) 64.74%,
    rgba(210, 210, 210, 0.689466) 70.96%,
    rgba(202, 202, 202, 0.636429) 77.5%,
    rgba(194, 194, 194, 0.580339) 84.46%,
    rgba(185, 185, 185, 0.521446) 91.93%,
    rgba(176, 176, 176, 0.46) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const grayShine = `
 background: radial-gradient(
    162.5% 162.5% at 50% 50%,
    #ffffff 0%,
    rgba(199, 199, 199, 0.01) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const colors = {
  grayShine,
  whiteShine,
};
