import { device } from '@/styles/common';
import styled from 'styled-components';

const StakingButton = styled.a`
  background: #ffffff;
  color: #000000;
  border-radius: 10px;
  height: 38px;
  padding: 20px 36px 20px 36px;
  justify-content: space-between;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;

  box-shadow: 0px 4px 12px 4px rgba(255, 255, 255, 0.15);

  &:hover {
    transform: scale(1.05);
    box-shadow-color: rgba(255, 255, 255, 0.25);
  }
  transition: transform 0.2s ease-in-out;
  @media ${device.mobileM} {
    padding: 0 14px;
  }
`;

export default StakingButton;
