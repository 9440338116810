import ManifoldLogo from '@/components/general/ManifoldLogo';
import { colors, device } from '@/styles/common';
import React from 'react';
import styled from 'styled-components';

function Section({ title, links }: { title: string; links: { name: string; href: string }[] }) {
  return (
    <SectionContainer>
      <SectionTitle>{title}</SectionTitle>
      <SectionLinks>
        {links.map((link, index) => (
          <SectionAnchor href={link.href} key={index} target="_blank">
            {link.name}
          </SectionAnchor>
        ))}
      </SectionLinks>
    </SectionContainer>
  );
}

function Footer({ hideFooter }: { hideFooter?: boolean }) {
  return (
    <>
      <FooterContainer hideFooter={hideFooter}>
        <LeftContainer>
          <ManifoldLogo />
          <Copyright>Copyright 2024</Copyright>
          <ManifoldAnchor>Maximum Extractable Value Technologies Ltd.</ManifoldAnchor>
        </LeftContainer>
        <DesktopContainer>
          <Section title="" links={[]} />
          <Section title="" links={[]} />
          <Section
            title="Overview"
            links={[
              { name: 'Forums', href: 'https://forums.mev.io' },
              {
                name: 'Migrate',
                href: '/redeemCreamETH2',
              },
              /*
            {
              name: 'Roadmap',
              href: '/roadmap',
            },
            {
              name: 'Comparison',
              href: '/comparison',
            },*/
              {
                name: 'Team',
                href: '/team',
              },
              /*{
              name: 'Legal',
              href: '/legal',
            },*/
            ]}
          />

          <Section
            title="Documentation"
            links={[
              { name: 'MEV Protocol', href: 'https://docs.mev.io/' },
              { name: 'GitHub', href: 'https://github.com/manifoldfinance' },
              /*            {
              name: 'Network Status',
              href: 'https://status.manifoldfinance.com/',
            },
            {
              name: 'Partner Inquiry',
              href: 'https://notionforms.io/forms/46b2a6bb-ac53-467c-ac3d-73623986c74f',
            },
            {
              name: 'Help',
              href: 'https://github.com/manifoldfinance/support',
            },*/
            ]}
          />
          <Section
            title="Community"
            links={[
              { name: '𝕏 (Twitter)', href: 'https://x.com/mevdotio' },
              { name: 'Telegram', href: 'https://t.me/manifoldfinance' },
              { name: 'Discord', href: 'https://discord.gg/mevprotocol' },
              { name: 'Blog', href: 'https://blog.mev.io' },
              { name: 'Medium', href: 'https://medium.com/@mevdotio' },
            ]}
          />
        </DesktopContainer>

        <MobileContainer>
          <Section
            title="Overview"
            links={[
              { name: 'Forums', href: 'https://forums.mev.io' },
              {
                name: 'Migrate',
                href: '/redeemCreamETH2',
              },
              {
                name: 'Team',
                href: '/team',
              },
              //{ name: 'Legal', href: '/legal' },
            ]}
          />

          <Section
            title="Docs"
            links={[
              {
                name: 'Network Status',
                href: 'https://status.manifoldfinance.com/',
              },
              {
                name: 'Partner Inquiry',
                href: 'https://notionforms.io/forms/46b2a6bb-ac53-467c-ac3d-73623986c74f',
              },
              {
                name: 'Help',
                href: 'https://github.com/manifoldfinance/support',
              },
            ]}
          />
          <Section
            title="Community"
            links={[
              { name: 'Source', href: 'https://github.com/manifoldfinance/web/' },
              { name: 'Github', href: 'https://github.com/manifoldfinance' },
              { name: '𝕏 (Twitter)', href: 'https://x.com/mevdotio' },
              { name: 'Telegram', href: 'https://t.me/manifoldfinance' },
            ]}
          />
        </MobileContainer>
      </FooterContainer>
    </>
  );
}

const FooterContainer = styled.div<{ hideFooter?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 90vw;
  align-self: center;
  margin: 72px 0;
  font-size: 14px;
  font-weight: 400;
  justify-content: space-between;

  display: ${(props) => (props.hideFooter ? 'none' : 'flex')};
  // position: ${(props) => (props.hideFooter ? 'absolute' : 'relative')};
  // bottom: ${(props) => (props.hideFooter ? '0' : 'auto')};

  @media ${device.mobileS} {
    padding-bottom: 64px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    font-size: 12px;
  }
`;
export const FAQContainer = styled.div<{ hideFooter?: boolean }>`
  display: flex;
  flex-direction: row;
  align-self: left;
  margin: 72px 0;
  font-size: 14px;
  font-weight: 400;

  display: ${(props) => (props.hideFooter ? 'none' : 'flex')};
  // position: ${(props) => (props.hideFooter ? 'absolute' : 'relative')};
  // bottom: ${(props) => (props.hideFooter ? '0' : 'auto')};
  // margin-bottom: ${(props) => (props.hideFooter ? '24px' : '72px')};

  @media ${device.mobileS} {
    padding-bottom: 64px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    font-size: 12px;
  }
`;

const DesktopContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-grow: 1;

  @media ${device.mobileS} {
    visibility: hidden;
    display: none;
  }
`;

const MobileContainer = styled.div`
  display: none;
  width: 100%;

  justify-content: space-between;
  margin-top: 32px;
  padding: 0 4px;
  @media ${device.mobileS} {
    display: flex;
  }
`;

// Left Container
const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Copyright = styled.div`
  margin-top: 12px;
  ${colors.grayShine};
`;

const ManifoldAnchor = styled.a`
  margin-top: 6px;
  text-decoration: underline;
  color: white;
`;

// Section
const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled.div`
  color: white;
  font-weight: 500;
  font-size: 16px;

  @media ${device.mobileS} {
    font-size: 14px;
  }
`;

const SectionLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 12px;
`;

const SectionAnchor = styled.a`
  color: #cbcaca;

  &:hover {
    color: white;
  }

  transition: color 0.2s ease-in-out;
`;

export default Footer;
