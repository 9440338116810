import { device } from '@/styles/common';
import { motion } from 'framer-motion';
import Image from 'next/image';
import styled from 'styled-components';

export const SmallCube = styled.img`
  width: 381px;
  position: absolute;
  z-index: 0;

  user-select: none;
  pointer-events: none;
`;

// Card

export const CardOuterContainer = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.mobileS} {
    justify-content: flex-start;
    padding-top: 128px;
    min-height: 740px;
  }
`;

export const CardOuterContainerWithdrawals = styled(motion.div)`
  width: 100vw;
  min-height: 100vh;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 90px;

  @media ${device.mobileS} {
    justify-content: flex-start;
    padding-top: 38px;
    min-height: 740px;
  }
`;

export const CardOuterContainerRoadmap = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media ${device.mobileS} {
    justify-content: flex-start;
    padding-top: 128px;
    min-height: 740px;
  }
`;

export const CardOuterContainerTeam = styled(motion.div)`
  height: 130vh;
  width: 100vw;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  @media ${device.mobileS} {
    justify-content: flex-start;
    padding-top: 128px;
    min-height: 1380px;
  }
`;

export const CardInnerContainer = styled(motion.div)`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(27, 31, 44, 0.475) 0%,
    rgba(27, 31, 44, 0.5185) 46.35%,
    rgba(27, 31, 44, 0.85) 100%
  );
  border-radius: 16px;
  overflow: hidden;
  height: 400px;
  width: 542px;
  backdrop-filter: blur(14px);
  z-index: 1;

  display: flex;
  flex-direction: column;

  @media ${device.mobileS} {
    width: calc(100vw - 32px);
    // height: unset;
    // min-height: 400px;
  }
`;

export const CardInnerContainerWithdrawals = styled(motion.div)`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(27, 31, 44, 0.475) 0%,
    rgba(27, 31, 44, 0.5185) 46.35%,
    rgba(27, 31, 44, 0.85) 100%
  );
  border-radius: 16px;
  overflow: hidden;
  width: 542px;
  backdrop-filter: blur(14px);
  z-index: 1;

  display: flex;
  flex-direction: column;

  @media ${device.mobileS} {
    width: calc(100vw - 32px);
    // height: unset;
    // min-height: 400px;
  }
`;
export const CardInnerContainerManaInvites = styled(motion.div)`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(27, 31, 44, 0.475) 0%,
    rgba(27, 31, 44, 0.5185) 46.35%,
    rgba(27, 31, 44, 0.85) 100%
  );
  border-radius: 16px;
  overflow: hidden;
  width: 542px;
  backdrop-filter: blur(14px);
  z-index: 1;

  display: flex;
  flex-direction: column;

  @media ${device.mobileS} {
    width: calc(100vw - 32px);
    // height: unset;
    // min-height: 400px;
  }
`;
export const CardInnerContainerMana = styled(motion.div)`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(27, 31, 44, 0.475) 0%,
    rgba(27, 31, 44, 0.5185) 46.35%,
    rgba(27, 31, 44, 0.85) 100%
  );
  border-radius: 16px;
  overflow: hidden;
  width: 220px;
  backdrop-filter: blur(14px);
  z-index: 1;
  margin-right: 20px;

  @media ${device.mobileS} {
    width: calc(100vw - 32px);
    // height: unset;
    // min-height: 400px;
  }
`;

export const CardInnerContainerTeam = styled(motion.div)`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(27, 31, 44, 0.475) 0%,
    rgba(27, 31, 44, 0.5185) 46.35%,
    rgba(27, 31, 44, 0.85) 100%
  );
  border-radius: 16px;
  overflow: hidden;
  margin-top: 90px;
  height: 1100px;
  width: 800px;
  backdrop-filter: blur(14px);
  z-index: 1;

  display: flex;
  flex-direction: column;

  @media ${device.mobileS} {
    overflow-y: scroll;
    margin-top: 0px;
    height: 1400px;
    width: calc(100vw - 22px);
    // min-height: 400px;
  }
`;

export const CardInnerContainerRoadmap = styled(motion.div)`
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(27, 31, 44, 0.475) 0%,
    rgba(27, 31, 44, 0.5185) 46.35%,
    rgba(27, 31, 44, 0.85) 100%
  );
  border-radius: 16px;
  overflow: hidden;
  margin-top: 90px;
  height: 700px;
  width: 800px;
  backdrop-filter: blur(14px);
  z-index: 1;

  display: flex;
  flex-direction: column;

  @media ${device.mobileS} {
    margin-top: 0px;
    width: calc(100vw - 32px);
    // height: unset;
    // min-height: 400px;
  }
`;

// Other
export const HeaderContainer = styled.div`
  position: absolute;
  width: 100%;
  padding: 21px 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 2;

  @media ${device.mobileL} {
    padding: 24px 32px;
  }
`;

export const LogoImage = styled(Image).attrs({
  width: 136,
  height: 0,
})`
  margin-top: 3px;
  height: auto;
`;
