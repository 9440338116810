import dynamic from 'next/dynamic'; // Importing the dynamic function for dynamic import
import Head from 'next/head'; // Importing the Head component from the next/head module

import { useRouter } from 'next/router';
import React, { use } from 'react'; // Importing React
import styled from 'styled-components'; // Importing the styled component module
import Footer from './Footer'; // Importing the Footer component

// Importing the FloatingHeader component dynamically
const FloatingHeader = dynamic(() => import('./FloatingHeader'), {
  ssr: false, // Disabling server-side rendering for the FloatingHeader component
});

// Defining the DefaultLayout component
function DefaultLayout({
  children, // The child components to be rendered
  hideFooter, // A flag to determine whether the footer should be hidden or not
}: {
  children: React.ReactNode; // The type for children prop
  hideFooter?: boolean; // The type for hideFooter prop
}) {
  const path = useRouter().asPath; // Getting the current path from the router object
  return (
    <>
      {/* Defining the head of the HTML document */}
      <Head>
        {/* Defining the title of the document */}
        <title>mevETH - Ethereum LSD Protocol</title>
        {/* Setting the favicon */}
        {path.indexOf('mana') > -1 ? (
          <link rel="icon" href="/svg/mana.svg" />
        ) : (
          <link rel="icon" href="/favicon.ico" />
        )}
        {/* Setting the Apple touch icon */}
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        {/* Adding support for mstile-144x144.png */}

        <meta property="og:url" content="https://mev.io" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="mevETH - Ethereum LSD Protocol" />
        <meta
          property="og:description"
          content="Ethereum's next-generation seamless liquid staking protocol. Built from the ground up for MEV capture and Validator rewards."
        />
        <meta property="og:image" content="https://mev.io/images/twitter-card.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@mevdotio" />
        <meta name="twitter:creator" content="@mevdotio" />
        <meta name="twitter:title" content="mevETH - Ethereum LSD Protocol" />
        <meta
          name="twitter:description"
          content="Ethereum's next-generation seamless liquid staking protocol. Built from the ground up for MEV capture and Validator rewards."
        />
        <meta name="twitter:image" content="https://mev.io/images/twitter-card.png" />
        {/* Defining the meta viewport */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      {/* Defining the main content */}
      <Main>
        {/* Rendering the FloatingHeader component */}
        <FloatingHeader />
        {/* Rendering the child components */}
        {children}
        {/* Rendering the Footer component */}
        <Footer hideFooter={hideFooter} />
      </Main>
    </>
  );
}

// Styling the Main component
const Main = styled.main`
  display: flex;
  flex-direction: column;

  min-height: 100vh;
  min-width: 100vw;
  background-image: url('/images/bg.png');
  background-size: cover;
`;

// Exporting the DefaultLayout component as the default export of the module
export default DefaultLayout;
